<template>
  <transition name="fade">
    <div>
      <Hero alt="privateDining" heading="private dining" />
      <Gallery
        :type="6"
        :slides="gallery"
        heading="private dining"
        brochure="true"
        :info="
          `Op zoek naar een bijzondere plek voor een smakelijke zakenlunch, borrel, seminar of een feestelijk diner? De Open Keuken van Babel biedt de mogelijkheid te genieten op een berg aan kennis, onder het genot van een van de mooiste uitzichten over de stad.<br><br>De private dining-ruimte, grenzend aan het restaurant en dakterras, is voorzien van een groot kookeiland en leent zich uitstekend voor besloten borrels, diners, seminars met een capaciteit van max. 40 gasten.<br><br>In onze brochure vind je enkele voorbeelden van het aanbod van de Open Keuken van Babel. Deze arrangementen zijn ter inspiratie; wij behandelen iedere aanvraag als maatwerk.<br><br>Bekijk hier de <a href='` +
          brochureLinkNL +
          `' target='_blank'>brochure</a> of neem <a href='#contact'>contact</a> met ons op voor meer informatie.<br><br>`
        "
      />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gallery from "@/components/Gallery.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { brochureLinkNL } from "@/data/brochureLinks.js";

import AOS from "aos";

export default {
  name: "PrivateDining",

  components: {
    Hero,
    Gallery,
    Form,
    Footer
  },

  data() {
    return {
      gallery: [
        { delay: 0, bg: "Dining-1" },
        { delay: 100, bg: "Dining-2" },
        { delay: 200, bg: "Dining-3" },
        { delay: 300, bg: "Dining-4" },
        { delay: 400, bg: "Dining-5" },
        { delay: 500, bg: "Dining-6" }
      ]
    };
  },

  computed: {
    brochureLinkNL() {
      return brochureLinkNL;
    }
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
